<template>
  <div>

    <div class="margen" style="display: flex; width:100%;">
      <div v-if="tableName != 'Tabla'" style="display: flex; width:100%; justify-content: flex-start;">
          <hr class="vr"/>
          <p class="tblName pb-2">{{tableName}}</p>
      </div>
      <div style="display: flex; width:100%; justify-content: flex-end;">
        <slot name="actionButtonsLeft" v-if="actionButtonsLeftSlot"></slot>
        <slot name="actionButtonsRight" v-if="actionButtonsRightSlot"></slot>
      </div>
    </div>



  <div  style="width:100%;overflow-x:auto;" id="tableScrollX">
    <vue-table
      ref="vueTable"
      @sort="sortBy"
      @selectAll="selectAll"
      :selectable="isSelectable"
      :sortKey="sortKey"
      :dir="dir"
      :columns="columns"
      :idTabla="idTabla"
      :sortOrders="sortOrders"
      :table-classes="classes.table"
      :table-header-classes="classes['t-head']"
      :table-row-classes="classes['t-head-tr']"
      :table-container-classes="classes['table-container']"
      :modalTableBool="modalTable"
    >
      <template v-if="bodySlot">
         <template v-if="loading">
            <tbody>
                <tr>
                    <td :colspan="columns.length+1" >
                        <v-progress-linear class="loading" :active="loading" color="blue" indeterminate></v-progress-linear>
                    </td>
                </tr>
            </tbody>
        </template>

        <template v-else-if="displayedRows.length == 0">
        <tbody>
                <tr>
                    <td :class="emptyTable" :colspan="columns.length+1">
                        No hay elementos para mostrar
                    </td>
                </tr>
            </tbody>
        </template>
        <template v-else>
          <slot name="body" v-if="bodySlot" :data="displayedRows"></slot>
        </template>
      </template>

      <template v-else>
        <tbody v-if="!!columns" :class="classes['t-body']">
          <tr
            :key="item.id"
            v-for="item in displayedRows"
            :class="classes['t-body-tr']"
          >
            <td
              :key="column.name"
              :class="classes.td"
              v-for="column in columns"
            >
              <data-table-cell
                :value="item"
                :name="column.name"
                :meta="column.meta"
                :event="column.event"
                :comp="column.component"
                :classes="column.classes"
                :handler="column.handler"
              ></data-table-cell>
            </td>
          </tr>
        </tbody>
      </template>
    </vue-table>
  </div>

    <div :class="['display-row','pagination-container',showPagination ? '':'nobar',  modalTable?'modal':'']">
      <div>
        <template v-if="showPagination">
        <slot
          :meta="data.meta"
          name="pagination"
          :links="data.links"
          v-if="paginationSlot"
        ></slot>
        <data-table-pagination
          v-else
          @getPageAction="getPageAction"
          :short="shortPagination"
          :from="tableData.from"
          :to="tableData.to"
          :total="rows.length"
          :currentPage="tableData.currentPage"
          :lastPage="tableData.lastPage"
          :perPage="perPage"
          :tableData="tableData"
          :modalTableBool="modalTable"
        ></data-table-pagination>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import UrlFilters from "./mixins/UrlFilters";
import VueTable from "./Table.vue";
import DataTableCell from "./DatatableCell.vue";
import DataTableFilters from "./DatatableFilters.vue";
import DataTablePagination from "./DatatablePagination.vue";
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  mounted() {
    this.columns.forEach((column) => {
      this.sortOrders[column.name] = -1;
    });
    this.tableScroll();
  },
  updated(){
      this.tableScroll();
  },
  mixins: [UrlFilters],
  watch: {
    "tableData.items_to_show": function(nuevo, viejo) {
      if(nuevo != viejo) {
        this.tableData.currentPage = 1;
      }
    },
    
  },
  components: {
    "vue-table": VueTable,
    "data-table-cell": DataTableCell,
    "data-table-filters": DataTableFilters,
    "data-table-pagination": DataTablePagination,
  },
  data() {
    return {
      data: {},
      selectedItems   : [],
      sortKey: "id",
      dir:'desc',
      sortOrders: {},
      tableData: {
        paginate: true,
        currentPage: 1,
        from: 1,
        to: 1,
        lastPage: 1,
        items_to_show: this.perPage[0],
      },
      tableName: this.tableName
    };
  },
  props: {
    valCheck:{
      type: String,
      default: "id",
    },
    idTabla: {
      type: String,
      default: "tableScrollSimple",
    },
    tableName: {
      type :String,
      default: "Tabla"
    },
    emptyTable:{
      type :String,
      default: "emptyTable"
    },
    orderBy: {
      type: String,
      default: "id",
    },
    columns: {
      type: Array,
      default: () => [],
      required: true,
    },
    rows: {
      type: Array,
      default: () => [],
      required: true,
    },
    showPagination: {
        type: Boolean,
        default: true
    },
    pagination: {
      type: Object,
      default: () => ({
        limit: 1,
        align: "right",
      }),
    },
    perPage: {
      type: Array,
      default: () => ["10", "25", "50"],
    },
    orderDir: {
      type: String,
      default: "asc",
      validator: function(value) {
        return ["asc", "desc"].indexOf(value) !== -1;
      },
    },
    shortPagination: {
      type: [Boolean],
      default: false,
    },
    modalTable: {
      type: [Boolean],
      default: false,
    },
    classes: {
      type: Object,
      default: () => ({
        "table-container": {
          "table-responsive": true,
        },
        table: {
          table: true,
          "table-striped": true,
          "table-dark": true,
        },
        "t-head": {},
        "t-body": {},
        td: {},
        th: {},
      }),
    },
    isSelectable: {
      type: [Boolean],
      default: false,
    },
    selected: {
      type: Array,
      default: () => ([]),
    },
     loading: {
          type: Boolean,
          default: false,
      },
  },
  methods: {
    sortBy(key, columnName = null) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      //this.tableData.column   = columnName ? columnName : key;
      //this.tableData.dir      = this.sortOrders[key] === 1 ? 'desc' : 'asc';

      this.tableData.column   = columnName ? columnName : key;
      this.dir = this.sortOrders[key] === 1 ? 'asc' : 'desc';

      let columnaOrden = {
        order_by: this.sortKey,
        dir:this.dir
      };
      //Object.assign(this.getRequestPayload.params, columnaOrden);
      this.$emit('filtroCampo', columnaOrden);
      
    },
    getPageAction(value) {
      this.tableData.currentPage = value;
    },
    unselectAll() {
      this.$refs.vueTable.selectAll = false;
    },
    paginate() {
      this.tableData.from     = (this.tableData.currentPage * this.tableData.items_to_show) - this.tableData.items_to_show;
      this.tableData.to       = (this.tableData.currentPage * this.tableData.items_to_show);
      this.tableData.lastPage = Math.ceil(this.rows.length / this.tableData.items_to_show);

      return this.rows.slice(this.tableData.from, this.tableData.to);
    },
    selectAll(value) {
      if(value) {
          for (let item of this.displayedRows) {
            if(!this.selectedItems.includes(item[this.valCheck]))
              this.selectedItems.push(item[this.valCheck]);
          }
      } else {
          this.selectedItems = [];
      }
      /* if(value) {
          for (let i in this.displayedRows) {
              this.selectedItems.push(this.displayedRows[i].id);
          }
      } else {
          this.selectedItems = []
      } */

      this.$emit('selectAllEvent', value, this.selectedItems);
    },
    tableScroll(){
        const elementScrollX = document.querySelector('#tableScrollX');
        if(elementScrollX) {
            const ps = new PerfectScrollbar(elementScrollX, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20
            });
        }
    },
  },
  computed: {
    paginationSlot() {
      if (this.$scopedSlots) {
        return this.$scopedSlots.pagination;
      }
      return null;
    },
    bodySlot() {
      if (this.$scopedSlots) {
        return this.$scopedSlots.body;
      }
      return null;
    },
    displayedRows() {
      return this.paginate();
    },
    actionButtonsLeftSlot() {
      if (this.$scopedSlots) {
        return this.$scopedSlots.actionButtonsLeft;
      }
      return null;
    },
    actionButtonsRightSlot() {
      if (this.$scopedSlots) {
        return this.$scopedSlots.actionButtonsRight;
      }
      return null;
    },
  },
};
</script>

<style scoped>
.emptyTable{
    padding-top: 50px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #c5c5c5;
}
.emptyTableWhite{
    text-align: center;
    font-size: 18px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #ffffff;
    color: #c5c5c5;
}
.display-row {
  /* display: inline-block; */
  text-align: center;
}
.display-row.pagination-container {
  width: 100%;
  text-align: right;
  background-color: #fdfcfc;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  padding: 0px 43px;
  padding-top: 20px;

  box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.2);
}
.display-row.pagination-container > div {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #c4c4c4;
}
.display-row.pagination-container.modal {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: none;
    background-color: white;
}
.display-row.pagination-container.nobar > div {
  border-top-width: 1px;
  border-top-style: none;
  border-top-color: #c4c4c4;
}

#btnBusquedaAvanzada {
  display: none !important;
}

.pull-right {
  text-align: right !important;
}

#contenedor {
  margin-top: -6%;
}

.icon-container {
  width: 42px;
  height: 42px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 100%;

  text-align: center;
  padding: 11px;
}

.icon-container:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 15px 15px 0px;
  -webkit-transform: translatey(-5px);
  transform: translatey(-5px);
  cursor: pointer;
}

.margen {
  margin-bottom: 20px;
  margin-right: 10px;
}
</style>
