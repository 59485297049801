<template>
    <div id="pageTable">      
        <v-container xl fluid>
            <v-row>
                <v-overlay :z-index="999" :value="overlay" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{fraseOverlay}}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Seguridad social'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="true"
                        :isSelectable="true"
                        :unselected="unselected_general"
                        @selectAllEvent="selectAll"
                        @loadModalData="loadModalData"
                        :valCheck="'id'"
                        :idTabla="'tablaIDSE'"
                    >
                        <template slot="actionButtonsLeft">
                            <v-menu offset-y content-class="menuClassAcciones">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        class="btnAcciones pr-1 mr-3 pl-4"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        Descargar formato
                                        <template  v-for="(item,valor) in attrs" >
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2 pr-2" color='#FFFFFF'>expand_less</v-icon>
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2 pr-2" color='#FFFFFF'>expand_more</v-icon>
                                        </template>
                                    </v-btn>
                                </template>
                                <div class="btnAccionesContenedor">
                                    <div @click="descargarFormato('ingreso')" class="btnAccionesItem py-2">
                                        <span>Formato ingreso</span>
                                    </div>
                                    <div @click="descargarFormato('baja')" class="btnAccionesItem py-2">
                                        <span>Formato baja</span>
                                    </div>
                                    <div @click="descargarFormato('modificacion')" class="btnAccionesItem py-2">
                                        <span>Formato modificación salarial</span>
                                    </div>
                                </div>
                            </v-menu>
                            <v-menu offset-y content-class="menuClassAcciones">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        class="btnAcciones pr-1 mr-3 pl-4"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        Tipos de reporte
                                        <template  v-for="(item,valor) in attrs" >
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2 pr-2" color='#FFFFFF'>expand_less</v-icon>
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2 pr-2" color='#FFFFFF'>expand_more</v-icon>
                                        </template>
                                    </v-btn>
                                </template>
                                <div class="btnAccionesContenedor">
                                    <div @click="descargarReporte('ingreso')" class="btnAccionesItem py-2">
                                        <span>Reporte para altas</span>
                                    </div>
                                    <div @click="descargarReporte('baja')" class="btnAccionesItem py-2">
                                        <span>Reporte para bajas</span>
                                    </div>
                                </div>
                            </v-menu>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12" class="py-0" >
                                <v-text-field
                                outlined
                                label="Nombre empleado"
                                v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin'">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="sucursal_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'">
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="departamentos"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Departamento"
                                        persistent-hint
                                        v-model="departamento_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Tipo de trabajador"
                                    :items="itemsTiposTrabajador"
                                    item-text="descripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="imss_tipo_trabajador_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Jornada reducida"
                                    :items="itemsJornadas"
                                    item-text="descripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="imss_semana_jornada_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12">
                                <div class="RangoFecha">
                                    <span>Rango de fechas de ingreso</span>
                                </div>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="min_fecha_ingreso"
                                    label="Fecha inicio"
                                    placeholder="dd/mm/aaaa"
                                    ref="min_fecha_ingreso"
                                />
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="max_fecha_ingreso"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="max_fecha_ingreso"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <div class="RangoFecha">
                                    <span>Rango de fechas de baja</span>
                                </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="min_fecha_baja"
                                    label="Fecha inicio"
                                    placeholder="dd/mm/aaaa"
                                    ref="min_fecha_baja"
                                />
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="max_fecha_baja"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="max_fecha_baja"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-checkbox
                                    v-model="checkboxBaja"
                                    :label="`Empleados de baja`"
                                ></v-checkbox>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td style="width:115px !important; ">
                                    <div >
                                        <v-checkbox class="mb-5" primary hide-details :value="item.id" v-model="selected_general" ></v-checkbox>
                                    </div>
                                </td> 
                                <td style="max-width:500px; min-width:300px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" style="max-width:500px; min-width:300px">
                                                <v-list class="py-0 my-0" style="background:transparent;">
                                                    <v-list-item class="py-0 my-0 px-0" style="background:transparent;">
                                                        <v-list-item-avatar class="py-0 my-0" >
                                                            <v-img class="py-0 my-0" :src="urlImg+item.foto"></v-img>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="ml-0 py-0 my-0">
                                                            <v-list-item-title class="tblPrincipal text-wrap">{{ item.nombre_completo }}</v-list-item-title>
                                                            <v-list-item-title class="tblSecundario text-mute">{{item.nombre_departamento}}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </template>
                                        <span> {{item.nombre_completo }} </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <div>
                                        {{item.tipo_trabajador}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{item.tipo_jornada}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{formatoMoneda(item.salario_base_cotizacion)}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{item.unidad_medicina_familiar}}
                                    </div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('historico', item)">
                                                <v-icon class="tamIconoBoton invertirColorBotones">assignment</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Historico</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="700px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Empleado" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Empleado" 
                                                    class="pa-0 ma-0" 
                                                    v-model="idse.nombre_completo" 
                                                    :error-messages="errors" 
                                                    required
                                                    readonly
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Tipo de trabajador" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Tipo de trabajador"
                                                    :items="itemsTiposTrabajador"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="idse.imss_tipo_trabajador_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Tipo de salario" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Tipo de salario"
                                                    :items="itemsSalarios"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="idse.imss_tipo_salario_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Jornada reducida" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Jornada reducida"
                                                    :items="itemsJornadas"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="idse.imss_semana_jornada_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyMoneyValidate
                                                outlined
                                                readonly
                                                label="SBC"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="idse.salario_base_cotizacion"
                                                v-bind:options="options"
                                                ref="salario_diario"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <v-text-field 
                                                outlined
                                                label="UMF" 
                                                class="pa-0 ma-0" 
                                                v-model="idse.unidad_medicina_familiar"
                                                required
                                                v-mask="'###'"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogHistorial" persistent max-width="800px" scrollable class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalHistorico"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card class="borde-card" elevation="0">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                
                                    <div class="headerModal ">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                    
                                </v-card-title>
                                <v-btn 
                                    color="#004BAF" 
                                    class="btnAdd float-right mr-10 mt-n8" 
                                    right
                                    rounded 
                                    @click="abrirModalAcuse()">
                                    <v-icon dark>add</v-icon>Agregar
                                </v-btn>
                                

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModalHistorico">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModalHistorico" grid-list-md id="contenedor" class="mt-3">
                                        <v-row>
                                            <simple-table
                                                class="tablaWidth"
                                                :rows="itemsHistorial"
                                                :columns="headersTableHistorial"
                                                :perPage="[10, 25, 50, 100]"
                                                shortPagination
                                                :modalTable="true"
                                                :emptyTable="'emptyTableWhite'"
                                                :classes="tableClasses"
                                            >
                                                <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                                                    <tr :key="item.id" v-for="(item, index) in data">
                                                        <td style="background-color: #ffffff !important;"><div>{{item.tipo_movimiento.descripcion}}</div></td>
                                                        <td style="background-color: #ffffff !important;"><div>{{item.observaciones}}</div></td>
                                                        <td style="background-color: #ffffff !important;">
                                                            <div class="tblOpciones">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" @click="descargarAcuse(item)" class="botonHover" icon fab dark small>
                                                                        <v-icon class="outlined_v_icon tamIconoBoton iconoDelgadoBoton">download</v-icon>
                                                                    </v-btn>
                                                                    </template>
                                                                    <span>Descargar</span>
                                                                </v-tooltip>
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" @click="eliminar(item)" class="botonHover" icon fab dark small>
                                                                        <v-icon class="material-icons tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                                    </v-btn>
                                                                    </template>
                                                                    <span>Eliminar</span>
                                                                </v-tooltip>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </simple-table>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                    >
                                        Cerrar
                                    </button>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogAcuse" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalAcuse"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form2" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                                <div>
                                    <h2 class="titleModalAcuse">Adjuntar acuse</h2>
                                </div>
                            </v-card-title>
                            

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModalAcuse">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModalAcuse" grid-list-md id="contenedor" class="mt-3">
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Tipo movimiento" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Tipo movimiento"
                                                    :items="itemsMovimientos"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="acuse.IMSS_tipo_movimiento_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="acuse.archivo"
                                                    accept=".jpeg,.jpg,.png,.pdf"
                                                    required 
                                                    :error-messages="errors"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                >
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-textarea
                                                outlined
                                                label="Observaciones"
                                                v-model="acuse.observaciones"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModalAcuse()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardarAcuse()"
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Vue from "vue";
import Datatable from '@/components/datatable/Datatable';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";
import clientesApi from '@/api/clientes';
import empresaApi from '@/api/empresas';
import sucursalApi from '@/api/sucursales';
import departamentoApi from '@/api/departamentos';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import idseApi from '@/api/idse';



export default {
    components: {
        'data-table': Datatable,
        "simple-table": DatatableSimple,
        VuetifyMoneyValidate,
        VuetifyDatePicker
    },
    data() {
        return {
            url:"imss/find",
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Tipo de trabajador',
                    name: 'tipo_trabajador',
                    filterable: true
                },
                {
                    label: 'Jornada',
                    name: 'jornada',
                    filterable: true
                },
                {
                    label: 'SBC',
                    name: 'salario_base_cotizacion',
                    filterable: false
                },
                {
                    label: 'UMF',
                    name: 'unidad_medicina_familiar',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            headersTableHistorial : [
                {
                    label: 'Tipo de movimiento',
                    name: 'tipo_movimiento',
                    filterable: false
                },
                {
                    label: 'Observaciones',
                    name: 'observaciones',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {
                activo      : true,
                order_by    : 'nombre'
            },
            tableData       : {},
            isLoading       : true,
            isLoadingModal  : false,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            idse            : {
                empleado_id                 : null,
                nombre_completo             : null,
                imss_tipo_trabajador_id     : null,
                imss_tipo_salario_id        : null,
                imss_semana_jornada_id      : null,
                salario_base_cotizacion     : null,
                unidad_medicina_familiar    : null,
            },
            acuse                       : {
                empleado_id             : null,
                IMSS_tipo_movimiento_id : null,
                archivo                 : null,
                observaciones           : null
            },
            userData                : this.$session.get('usuario'),
            accion                  : null,
            tituloModal             : null,
            dialog                  : false,
            isSaving                : false,
            imagen                  : '',
            datosLogin              : null,
            rol                     : null,
            urlImg                  : null,
            itemsMovimientos        : [],
            itemsSalarios           : [],
            itemsJornadas           : [],
            itemsTiposTrabajador    : [],
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            selected_general        : [],
            unselected_general      : [],
            masivo                  : false,
            checkAll                : false,
            urlImg                  : '',
            dialogHistorial         : false,
            isLoadingModalHistorico : false,
            itemsHistorial          : [],
            dialogAcuse             : false,
            empleado_id             : null,
            overlay                 : false,
            fraseOverlay            : "Descargando",
            cliente_value           : null,
            empresa_value           : null,
            sucursal_value          : null,
            departamento_value      : null,
            clientes                : [],
            empresas                : [],
            sucursales              : [],
            departamentos           : [],
            nombre                  : null,
            imss_tipo_trabajador_id : null,
            imss_semana_jornada_id  : null,
            min_fecha_ingreso       : null,
            max_fecha_ingreso       : null,
            min_fecha_baja          : null,
            max_fecha_baja          : null,
            checkboxBaja            : false,
            tableClasses: {
                table: {
                    "no-box-shadow":true
                },
            },

        }
    },
    watch:{
        selected_general:function(value) {
            if(value.length > 0 ){
                this.masivo =  true;
            }
            else{
                this.masivo =  false;
            }
        },
        isLoadingModalHistorico:function(val){
            if(!val){
                setTimeout(function(){ document.querySelector('#tableScrollSimple tbody').style.minWidth = "400px"; }, 100);
            }
        },
        /**
         * @function cliente_value Se ejecuta cuando hay un cambio en la variable cliente_value.
         * @description Se ejecuta cuando hay un cambio en la variable cliente_value, se ejecuta el método cambiaCliente().
         * @param {int} val Es el id de cliente (registro) seleccionado y se le envia al método cambiaCliente().
         */
        cliente_value: function(val) {
            this.cambiaCliente(val);
        },
        /**
         * @function empresa_value Se ejecuta cuando hay un cambio en la variable empresa_value.
         * @description Se ejecuta cuando hay un cambio en la variable empresa_value, se ejecuta el método cambiaEmpresa().
         * @param {int} val Es el id de empresa (registro) seleccionado y se le envia al método cambiaEmpresa().
         */
        empresa_value: function(val) {  
            this.cambiaEmpresa(val);
        },
        /**
         * @function sucursal_value Se ejecuta cuando hay un cambio en la variable sucursal_value.
         * @description Se ejecuta cuando hay un cambio en la variable sucursal_value, se ejecuta el método cambiaSucursal().
         * @param {int} val Es el id de cliente (registro) seleccionado y se le envia al método cambiaSucursal().
         */
        sucursal_value: function(val) {  
            this.cambiaSucursal(val);
        },
    },
    methods: {
        /**
         * @method open Este método realiza la acción de hacer clic sobre el campo para adjuntar el acuse.
         * @description Realiza la acción de hacer clic sobre el campo para adjuntar el acuse.
        */
        open(){
            document.getElementById("fileDocument").click();
        },
        /**
         * @method abrirModal Este método realiza la acción para abrir un modal.
         * @description Este método realiza la acción para abrir un modal dependiento de la acción que se le envie, puede update o historico.
         * @param {string} accion Esta variable puete tomar el valor de 'update' o 'historico'.
         * @param {Object} data Esta variable es un objeto, son los datos de un empleado.
        */
        async abrirModal(accion, data = null){
            this.accion         = accion;
            this.isLoadingModal = true;
            this.isLoadingModalHistorico = true;

            if(accion == "add"){
                this.tituloModal    = "Registrar movimiento afiliatorio";
                this.imagen         = "/static/modal/idseUpdate.svg";
                setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
                this.isLoadingModal  = false;
            } 
            else if(accion == "update") {
                this.tituloModal        = "Actualizar afiliación de empleado";
                this.imagen             = "/static/modal/idseUpdate.png";
                this.idse.empleado_id                 = data.id;
                this.idse.nombre_completo             = data.nombre_completo;
                this.idse.imss_tipo_trabajador_id     = data.IMSS_tipo_trabajador_id;
                this.idse.imss_tipo_salario_id        = data.IMSS_tipo_salario_id;
                this.idse.imss_semana_jornada_id      = data.IMSS_semana_jornada_id;
                this.idse.salario_base_cotizacion     = data.salario_base_cotizacion;
                this.idse.unidad_medicina_familiar    = data.unidad_medicina_familiar;
                setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
                this.isLoadingModal  = false;
            }
            else if(accion == "historico") {
                this.tituloModal        = "Historial de acuse";
                this.imagen             = "/static/modal/idseHistorial.png";
                setTimeout(function(){ document.getElementById("btnModalHistorico").click(); }, 100);

                this.empleado_id = data.id;

                await this.getListarAcuses();
                this.isLoadingModalHistorico = false;
            }
        },
        /**
         * @method cerrarModal Este método cierra el modal.
         * @description Este método cierra el modal en cuestion y llama al metodo resetValues() para resetear los valores.
         */
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.dialogHistorial = false;
            
            this.resetValues();
        },
        /**
         * @method cerrarModalAcuse Este método cierra el modal acuse
         * @description Este método cierra el modal de acuse.
         */
        cerrarModalAcuse(){
            this.dialogAcuse= false;
            this.isSaving   = false;
            this.acuse                  = {
                empleado_id             : null,
                IMSS_tipo_movimiento_id : null,
                archivo                 : null,
                observaciones           : null
            };
            this.$nextTick(() => {
                this.$refs.form2.reset();
            });
        },
        /**
         * @method resetValues Este método resetea los valores.
         * @description Este método resetea los valores por defecto de modales y variables para tener un modal limpio a la hora de abrirlos de nuevo.
         */
        resetValues() {
            this.idse                       = {
                empleado_id                 : null,
                nombre_completo             : null,
                imss_tipo_trabajador_id     : null,
                imss_tipo_salario_id        : null,
                imss_semana_jornada_id      : null,
                salario_base_cotizacion     : null,
                unidad_medicina_familiar    : null,
            };
            this.acuse                  = {
                empleado_id             : null,
                IMSS_tipo_movimiento_id : null,
                archivo                 : null,
                observaciones           : null
            };

            if (this.rol == "root") {
                this.cliente_value = null;
                this.empresa_value = null;
                this.sucursal_value = null;
                this.departamento_value = null;
                this.empresas = [];
                this.sucursales = [];
                this.departamentos = [];
            }

            if (this.rol == "admin") {
                this.empresa_value = null;
                this.sucursal_value = null;
                this.departamento_value = null;
                this.sucursales = [];
                this.departamentos = [];
            }

            if (this.rol == "admin-empresa") {
                this.sucursal_value = null;
                this.departamento_value = null;
                this.departamentos = [];
            }
            if (this.rol == "admin-sucursal") {
                this.departamento_value = null;
            }
            
            this.nombre                     = null;
            this.imss_tipo_trabajador_id    = null;
            this.imss_semana_jornada_id     = null;
            this.min_fecha_ingreso          = null;
            this.max_fecha_ingreso          = null;
            this.min_fecha_baja             = null;
            this.max_fecha_baja             = null;
            this.checkboxBaja               = false;

            this.empleado_id    = null;

            if(this.$refs.min_fecha_ingreso != undefined || this.$refs.min_fecha_ingreso != null){
                this.$refs.min_fecha_ingreso.resetDate();
            }
            if(this.$refs.max_fecha_ingreso != undefined || this.$refs.max_fecha_ingreso != null){
                this.$refs.max_fecha_ingreso.resetDate();
            }
            if(this.$refs.min_fecha_baja != undefined || this.$refs.min_fecha_baja != null){
                this.$refs.min_fecha_baja.resetDate();
            }
            if(this.$refs.max_fecha_baja != undefined || this.$refs.max_fecha_baja != null){
                this.$refs.max_fecha_baja.resetDate();
            }
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        /**
         * @method eliminar Este método elimina el movimiento de la tabla historial.
         * @description Este método elimina el moviento afiliatorio en la tabla del historial.
         * @param {Object} data Esta variable es un objeto, son los datos del movimiento.
         */
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el moviento afiliatorio ",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    idseApi.deleteAcuse(data.id).then(response => {
                        Notify.Success("Movimiento afiliatorio eliminado", "El movimiento afiliatorio ha sido eliminado satisfactoriamente");
                        this.getListarAcuses();
                        this.cerrarModalAcuse();
                        this.isLoading = false;
                        this.isSaving = false;
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                   
                }
            )
        },
        /**
         * @method guardar Este método guarda los datos que se necesitan de un empleado.
         * @description Este método guarda los datos actualizados que se necesitan para empleado para poder realizar correctamente los movimientos afiliatorios 
         */
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                
                this.isLoading = true;
                idseApi.updateIMSS(this.idse).then((response) => {
                    Notify.Success("Operación exitosa", "El registro se actualizó satisfactoriamente.");
                    this.$refs.tabla.getData();
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isLoading = false;
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            });
        },
        /**
         * @method listar Este método sirve para cargar los datos del modulo.
         * @description Este método sirve para cargar los datos del modulo correctamente dependiendo del rol, este método se ejecuta en el create().
         */
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            this.urlImg = GetUrlImg.getURL();

            if (this.rol == "root") {
                this.getClientes();
                this.filters.rol_name   = this.rol;
                this.filters.usuario_id = this.datosLogin.id;
            }
 
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.rol_name   = this.rol;
                this.filters.usuario_id = this.datosLogin.id;
            }

            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.filters.rol_name   = this.rol;
                this.filters.usuario_id = this.datosLogin.id;
            }

            if(this.rol == "admin-sucursal"){
                this.cliente_value = this.datosLogin.cliente_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.filters.sucursal_id= this.datosLogin.sucursal_id;
                this.filters.rol_name   = this.rol;
                this.filters.usuario_id = this.datosLogin.id;
            }

            if(this.rol == "admin-depto"){
                this.cliente_value = this.datosLogin.cliente_id;
                this.departamento_value = this.datosLogin.departamento_id;
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.filters.sucursal_id= this.datosLogin.sucursal_id;
                this.filters.rol_name   = this.rol;
                this.filters.usuario_id = this.datosLogin.id;
            }
        },
        /**
         * @method getClientes Este método realiza la petición a la api de cliente
         * @description Este método realiza la petición a la api de clientes, este patición sirve para llenar el campo de clientes en la parte de los filtros.
         */
        async getClientes() {
            let param = { activo: true, paginate: false };
            await clientesApi.getClientes(param).then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },
        /**
         * @method cambiaCliente Este método realiza la petición a la api de empresas.
         * @description Este método realiza la petición a la api de empresas, esta petición sirve para llenar el campo de empresas en la parte de los filtros.
         * @param {int} cliente id de cliente, sirve para enviar en los paramentros de la api de empresas.
         */
        async cambiaCliente(cliente) {
            if(cliente == null){
                return;
            }

            let param = { activo: true, paginate: false, cliente_id: cliente };
            await empresaApi.getEmpresas(param).then(response => {
                this.empresas = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        /**
         * @method cambiaEmpresa Este método realiza la petición a la api de sucursales.
         * @description Este método realiza la petición a la api de sucursales, esta petición sirve para llenar el campo de sucursales en la parte de los filtros.
         * @param {int} empresa id de empresa, sirve para enviar en los  parametros de la api de sucursales.
         */
        async cambiaEmpresa(empresa) {
            if(empresa == null){
                return;
            }
            let parametros = { activo: true, paginate: false, empresa_id: empresa };
            await sucursalApi.getSucursales(parametros).then(response => {
                this.sucursales = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },
        /**
         * @method cambiaSucursal Este método realiza la petición a la api de departamentos.
         * @description Este método realiza la petición a la api de departamentos, esta petición sirve para llenar el campo de departamentos en la parte de los filtros.
         * @param {int} sucursal id de sucursal, sirve para enviar en los parametros de la api de departamentos.
         */
        async cambiaSucursal(sucursal) {
            if(sucursal == null){
                return;
            }
            let parametros = { activo: true, paginate: false, sucursal_id: sucursal };
            await departamentoApi.getDepartamentos(parametros).then(response => {
                this.departamentos = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los departamentos.");
            });
        },
        /**
         * @method selectEmployee Este método se realiza al dar clic al check de un empleado
         * @description Este método se realiza al dar clic al check de un empleado. Este método ya no se usa.
         * @param {int} id id de empleado.
         */
        selectEmployee(id){
            if(this.selected_general.includes(id)){
                let index = this.selected_general.indexOf(id);
                this.selected_general.splice(index, 1);
            }else{
               this.selected_general.push(id); 
            }

            if(this.checkAll){
                if(this.unselected_general.includes(id)){
                    let index = this.unselected_general.indexOf(id);
                    this.unselected_general.splice(index, 1);
                }else{
                    this.unselected_general.push(id); 
                }
            }
        },
        /**
         * @method selectAll Este método se realiza al dar clic en el check que esta en el header de la tabla principal.
         * @description Este método se realiza al dar clic en el check que esta en el header de la tabla principal. Se selecciones todos los check o se deseleccionan.
         * Se ejecuta con una emit del componente tabla.
         * @param {boolean} isSelected variable para saber si check es esta seleccionado o no.
         * @param {object} items variable donde vienen todos los items de los empledos.
         */
        selectAll(isSelected, items) {
            this.checkAll = isSelected;
            this.selected_general = items;

            if(!isSelected){
                this.unselected_general = [];
            }
        },
        /**
         * @method loadModalData Este método se ejecuta cuando se abre el modal de busqueda avanzada.
         * @description Este método se ejecuta cuando se abre el modal de busqueda avanzada. Se realizan las peticiones para llenar los campos que se requieran dependiendo del rol.
         * Se ejecuta como emit del componente tabla.
         */
        async loadModalData() {
            await this.resetValues();

            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
            else if (this.rol == "admin-depto") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.departamento_value = this.datosLogin.departamento_id;
            }
        },
        /**
         * @method setFilters Este método se ejecuta despues de dar clic en el botón buscar
         * @description Este método se ejecuta despues de dar clic en el botón buscar, se envian los filtros que se indicaron en el modal búsqueda. Y se reflejan en la tabla principal.
         * Se ejecuta como emit del componente tabla.
         */
        setFilters(){
            let nombre                      = this.nombre;
            let cliente                     = this.cliente_value;
            let empresa                     = this.empresa_value;
            let sucursal                    = this.sucursal_value;
            let departamento                = this.departamento_value;
            let imss_tipo_trabajador_id     = this.imss_tipo_trabajador_id;
            let imss_semana_jornada_id      = this.imss_semana_jornada_id;
            let min_fecha_ingreso           = this.min_fecha_ingreso;
            let max_fecha_ingreso           = this.max_fecha_ingreso;
            let min_fecha_baja              = this.min_fecha_baja;
            let max_fecha_baja              = this.max_fecha_baja;
            let checkboxBaja                = this.checkboxBaja;

            let activo = true;
            let baja = false;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                sucursal = this.datosLogin.sucursal_id;
            } else if (this.rol == "admin-depto") {
                departamento = this.datosLogin.departamento_id;
            }

            if ( checkboxBaja ){
                activo  = false;
                baja    = true;
            }
            else{
                activo  = true;
                baja    = false;
            }

            let filterParams = {
                nombre_completo     : nombre,
                cliente_id          : cliente,
                empresa_id          : empresa,
                sucursal_id         : sucursal,
                departamento_id     : departamento,
                imss_tipo_trabajador_id : imss_tipo_trabajador_id,
                imss_semana_jornada_id  : imss_semana_jornada_id,
                min_fecha_ingreso   : min_fecha_ingreso,
                max_fecha_ingreso   : max_fecha_ingreso,
                min_fecha_baja      : min_fecha_baja,
                max_fecha_baja      : max_fecha_baja,
                activo              : activo,
                baja                : baja,
                rol_name            : this.rol,
                usuario_id          : this.datosLogin.id
            };

            let defaultParams = {
                paginate: true,
                order_by: 'nombre',
                include_padres: true,
            };

            if (filterParams != null) {
                Object.assign(defaultParams, filterParams);
            }

            this.filters = defaultParams;

            this.resetValues();
        },
        /**
         * @method descargarAcuse Este método se ejecuta al dar clic en el botón descargar en el modal historial.
         * @description Este método se ejecuta al dar clic en el botón descargar en el modal historial. Descarga un archivo .txt con los datos del movimiento afiliatorio.
         * @param {object} item Objeto que tiene los datos del registro del movimiento afiliatorio.
         */
        async descargarAcuse(item = null) {
            this.overlay = true;
            let self = this;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "imss/descargar-acuse";
            var nombre_archivo = item.url.split("/");
            nombre_archivo = "acuse_" + nombre_archivo[3];
            let parametros = { url: item.url };
            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    FileDownload(response.data, nombre_archivo);
                    self.overlay = false;
                });
            } catch (error) {
                self.overlay = false;
                console.log(error);
            }
        },
        /**
         * @method descargarFormato Este método se ejecuta al dar clic en alguna de las opciones del botón "Descargar formato"
         * @description Este método se ejecuta al dar clic en alguna de las opciones del botón "Descargar formato", puede ser tres opciones 'ingreso', 'baja' o 'modificación salarial'.
         * @param {string} accion puede tomar los valores de 'ingreso', 'baja' o 'modificación salarial'.
         */
        descargarFormato(accion){
            if(this.selected_general.length == 0){
                Notify.ErrorToast("Seleccionar al menos un empleado");
                return;
            }

            this.overlay = true;
            let self = this;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "imss/generar-idse";
            var nombre_archivo = "acuse.txt";
            var tipoMovimiento = "";
            if(accion == "ingreso"){
              nombre_archivo = "acuse_ingreso.txt" 
              tipoMovimiento = "08"
            }
            else if(accion == "baja"){
                nombre_archivo = "acuse_baja.txt" 
                tipoMovimiento = "02"
            }
            else if(accion == "modificacion"){
                nombre_archivo = "acuse_modificacion_salarial.txt" 
                tipoMovimiento = "07"
            }
            let parametros = { 
                empleados: this.selected_general,
                tipo_movimiento:tipoMovimiento 
            };
            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    FileDownload(response.data, nombre_archivo);
                    self.overlay = false;
                }).catch(err => {
                    self.overlay = false;
                    Notify.ErrorToast("Error al descargar el archivo.");
                });
            } catch (error) {
                self.overlay = false;
                console.log(error);
            }
        },
        descargarReporte(accion){

        },
        /**
         * @method abrirModalAcuse Este método se ejecuta para abrir modal de para registrar un nuevo registro o movimiento afiliatorio.
         * @description Este método se ejecuta para abrir el modal para registrar un nuevo registro o movimiento afiliatorio.
         */
        abrirModalAcuse(){
            this.isLoadingModalAcuse = true;
            setTimeout(function(){ document.getElementById("btnModalAcuse").click(); }, 100);
            this.isLoadingModalAcuse = false;
        },
        /**
         * @method guardarAcuse Este método sirve para guardar los datos que se registraron en el modal acuse.
         * @description Este método sirve para guardar los datos que se registraron en el modal acuse. Se actualiza la tabla del historial de ser correcto el guardado.
         */
        guardarAcuse(){
            this.$refs.form2.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                let formData = new FormData();
                formData.append("empleado_id", this.empleado_id);
                formData.append("IMSS_tipo_movimiento_id", this.acuse.IMSS_tipo_movimiento_id);
                formData.append("archivo", this.acuse.archivo);
                formData.append("observaciones", this.acuse.observaciones);
                this.isLoading = true;

                 idseApi.storeAcuse(formData).then((response) => {
                    Notify.Success("Operación exitosa", "El movimiento afiliatorio se agrego satisfactoriamente.");
                    this.getListarAcuses();
                    this.cerrarModalAcuse();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isLoading = false;
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            });
        },
        /**
         * @method formatoMoneda Este método sirve para formatear el valor a formato moneda en la tabla principal.
         * @description Este método sirve para formatear el valor a formato moneda en la tabla principal.
         */
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },
        /**
         * @method getColeccionesIMSS Este método se ejecuta para llenar las colecciones del IMSS.
         * @description Este método se ejecuta para llenar las colecciones del IMSS. Se llenan los tipos de movimientos, los tipos de salario, los tipos de jornada y los tipos de trabajadores.
         * Se ejecuta el create(). Los datos se usan en el modal de actualizar los datos afiliatorios del empleado y en el modal del registro del acuse(movimiento afiliatorio).
         */
        async getColeccionesIMSS(){
            await idseApi.getColeccionesIMSS().then(response => {
                this.itemsMovimientos        = response.tipos_movimientos;
                this.itemsSalarios           = response.tipos_salarios;
                this.itemsJornadas           = response.semanas_jornadas;
                this.itemsTiposTrabajador    = response.tipos_trabajadores;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las colecciones");
            });
        },
        /**
         * @method getListarAcuses Este método se ejecuta al abrir el modal de historial de acuses.
         * @description Este método se ejecuta al abrir el modal de historial de acuses, se ejecuta cuando se elimina un registro o cuando se agrega un nuevo registro.
         */
        async getListarAcuses(){
            let parametros = {empleado_id: this.empleado_id}
            await idseApi.getListarAcuses(parametros).then((response) => {
                this.itemsHistorial = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Acuses");
            });
        }
    },
    created(){
        this.listar();
        this.getColeccionesIMSS();
    },
    mounted(){
    },
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .tablaWidth{
        width: 100%;
        width: -moz-available;          
        width: -webkit-fill-available;  
        width: fill-available;
    }
    #tableScrollSimple tbody{
        max-height:60vh !important;
        min-width: 400px !important;
        background-color: #fff !important;
    }
    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        height: 42px !important;
        border-radius: 30px;
        text-transform: none;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;

    }
    .btnAcciones:hover {
        height: 42px !important;
        border-radius: 30px;
        text-transform: none;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #FFF;
    }
    div.btnAccionesTitulo {
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesTitulo span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #1E2245;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
    .tablaVacia{
        padding-top: 50px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: red;
    }
    .titleModalAcuse {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #1E2245;
        padding-bottom: 10px;
    }
    #tablaIDSE tbody {
        display:block;
        max-height:60vh;
        min-width: 1500px;
        background-color: #fdfcfc;
    }
</style>
