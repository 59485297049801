<template>
  <div :class="tableContainerClasses">
    <table :class="['ck-table',modalTableBool?'modal':'', tableClasses]" :id="idTabla">
      <thead :class="tableHeaderClasses">
        <tr :class="tableRowClasses">
          <th v-if="selectable" style="width:115px !important;">
            <v-checkbox class="py-0 my-1" hide-details v-model="selectAll"></v-checkbox>
          </th>
          <th
            scope="col"
            :key="column.name"
            v-for="column in columns"
            :class="headerClasses(column)"
            :style="'width: ' + column.width + '%'"
            @click="column.filterable ? sort(column) : null"
          >
            <div class="align-center" :style="`display: flex; justify-content: ${column.align == null? 'left' : column.align}; margin-top:auto; margin-bottom:auto;`">
              
              <template v-if="column.showTooltip == true">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ column.label }}
                    </span>
                  </template>
                  <span v-html="column.mensajeTooltip"></span>
                </v-tooltip>
              </template>
              <template v-else>
                {{ column.label }} 
              </template>

              
              
              <template v-if="column.filterable">
                <div
                  class="filter align-center pt-1"
                  v-if="
                    column.filterable &&
                      column.name == currentSort &&
                      dir == 'asc'
                  "
                >
                  <i class="material-icons">expand_less</i>
                </div>
                <div class="filter align-center pt-1" v-else>
                  <i class="material-icons">expand_more</i>
                </div>
              </template>
            </div>
          </th>
        </tr>
      </thead>
      <slot> </slot>
    </table>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
export default {
  data() {
    return {
      currentSort: "",
      selectAll: false,
    };
  },
  props: {
    idTabla: {
      type: String,
      default: "tableScrollSimple",
    },
    modalTableBool: {
      type: [Boolean],
      default: false,
    },
    dir: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => [],
      required: true,
    },
    sortKey: {
      type: String,
      default: "",
    },
    sortOrders: {
      type: Object,
      default: () => ({}),
    },
    tableClasses: {
      type: Object,
      default: () => ({}),
    },
    tableHeaderClasses: {
      type: Object,
      default: () => ({
        "p-3": true,
        "text-left": true,
      }),
    },
    tableRowClasses: {
      type: Object,
      default: () => ({}),
    },
    tableContainerClasses: {
      type: Object,
      default: () => ({}),
    },
    selectable: {
      type: [Boolean],
      default: false,
    },
  },
  watch: {
    selectAll: {
      handler: function(newValue) {
        this.$emit("selectAll", this.selectAll);
      },
    },
  },
  methods: {
    headerClasses(column) {
      let classes = this.tableHeaderClasses;
      classes["table-header-sorting"] = column.filterable;
      return classes;
    },
    sort(column) {
      this.currentSort = column.name;
      this.$emit("sort", column.name, column.columnName);
    },
    tableScroll(){
        const elementScroll = document.querySelector(`#${this.idTabla} tbody`);
        if(elementScroll) {
            const ps = new PerfectScrollbar(elementScroll, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20
            });
        }
    },
  },
  mounted() {
      this.tableScroll();
  },
  updated(){
      this.tableScroll();
  }
};
</script>

<style scoped>
.filter {
  color: #96999a;
  cursor: pointer;
}
.inline-block {
  display: inline-block;
}

table {
  border-collapse: collapse;
  width: 100%;
  /* white-space: nowrap; */
  overflow-x: scroll;
  overflow-y: scroll;

  box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.2);
}
th {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #828282;
  background-color: #f6f5f6;
  padding: 13px 45px 13px 45px;
}
th > div{
    max-width: 15rem;
    /* text-align: center; */
    margin-right: auto;
    margin-left: auto;
}
td {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #1E2245;
  background-color: #fdfcfc;
  padding: 13px 45px 13px 45px;
  letter-spacing: -0.34px;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  max-width: 15rem;
}
td > div{
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    max-width: 15rem;
    margin-right: auto;
    margin-left: auto;
}
td > .loading{
        max-width: 100% !important;
    }
table.ck-table thead tr {
  height: 35px; /* euicab antes height: 66px;*/
}
table.ck-table thead tr th:first-child {
  border-top-left-radius: 20px;
}
table.ck-table thead tr th:last-child {
  border-top-right-radius: 20px;
}
table.ck-table thead.no-radius tr th:first-child {
  border-top-left-radius: 0px !important;
}
table.ck-table thead.no-radius tr th:last-child {
  border-top-right-radius: 0px;
}
table.ck-table thead.titulo-first-child tr th:first-child{
  padding-left: 79px;
}
table.ck-table thead.tamanio-head tr th{
  height: 66px;
}
tbody {
  display:block;
  max-height:60vh;
  min-width: 1000px;
  background-color: #fdfcfc;
}
tr {
  display:table;
  table-layout:fixed;
  width: 100%;
}

table.ck-table.modal  tbody {
  display:block;
  max-height:60vh;
  min-width: 100%;
  background-color: #fdfcfc;
}
</style>
