import Vue from 'vue';

export default {
    getColeccionesIMSS(parametros){
        return Vue.axios.get("catalogo/colecciones/imss", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    generarIDSE(parametros){
        return Vue.axios.post("imss/generar-idse", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getListarAcuses(parametros){
        return Vue.axios.get("imss/listar-acuses", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateIMSS(parametros){
        return Vue.axios.put("imss", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteAcuse(id){
        return Vue.axios.delete("imss/delete-acuse/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    storeAcuse(parametros){
        return Vue.axios.post("imss/store-acuse", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}